import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { get } from "lodash";
import { getUrlFor } from "../utils/imageUtils";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import SocialBar from "../components/SocialBar";
import withGraphQLErrors from "../hocs/withGraphQLErrors";
import ContentLayout from "../primitives/ContentLayout";
import SectionLayout from "../primitives/SectionLayout";
import PortableText from "../primitives/PortableText";
import LinkButton from "../primitives/LinkButton";
import ColumnLayout from "../primitives/ColumnLayout";
import Button from "../primitives/Button";
import LinkWithArrow from "../primitives/LinkWithArrow";
import logoApci from "../images/logo-apci.png";
import logoIndigodoc from "../images/logo-indigodoc.png";
import logoEurobiomed from "../images/logo-eurobiomed.png";
import logoAerospaceValley from "../images/logo-aerospace-valley.png";
import logoEden from "../images/logo-eden.png";
import logoGicat from "../images/logo-gicat.png";
import mapBigImage from "../images/map-big.jpg";
import mapMediumImage from "../images/map-medium.jpg";
import mapSmallImage from "../images/map-small.jpg";
import portraitRinato from "../images/portrait-rinato.jpg";
import photoCarreDuRoi1 from "../images/photo-carre-du-roi-1.jpg";
import photoCarreDuRoi2 from "../images/photo-carre-du-roi-2.jpg";
import styles from "../styles/contact.module.scss";

export const query = graphql`
  query ContactPageQuery {
    grid: sanityEmployeesGrid(_id: { eq: "employeesGrid" }) {
      employees {
        ... on SanityEmployee {
          fullName
          role
          thumbImage {
            asset {
              _id
            }
            crop {
              bottom
              left
              right
              top
            }
            hotspot {
              height
              x
              width
              y
            }
          }
        }
      }
    }
    page: sanityRoute(slug: { current: { eq: "contact" } }) {
      page {
        title
        subtitle
        _rawContent(resolveReferences: { maxDepth: 10 })
        meta {
          metaTitle
          metaDescription
        }
        openGraphImage {
          asset {
            _id
          }
        }
      }
    }
  }
`;

const status = {
  IDLE: "IDLE",
  INVALID: "INVALID",
  SUBMITTING: "SUBMITTING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

const ContactPage = ({ data }) => {
  const { grid = {}, page = {} } = data || {};
  const { page: pageContent = {} } = page;
  const [formStatus, setFormStatus] = useState(status.IDLE);

  const handleSubmit = e => {
    const emailRegex = new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$");
    const formElement = e.target;
    e.preventDefault();
    setFormStatus(status.SUBMITTING);
    const formData = new FormData(formElement);
    const emailData = formData.get("email");

    if (!emailData || !emailRegex.test(emailData)) {
      setFormStatus(status.INVALID);
      return;
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setFormStatus(status.SUCCESS);
        formElement.reset();
      })
      .catch(() => {
        setFormStatus(status.ERROR);
      });
  };

  return (
    <Layout>
      <Seo
        title={
          get(page, "page.meta.metaTitle", "") || get(page, "page.title", "")
        }
        description={get(page, "page.meta.metaDescription", "")}
        image={get(page, "page.openGraphImage", null)}
      />

      <ContentLayout size="large">
        <div className={styles.pageHero}>
          <div
            className={styles.pageHeroMedia}
            style={{ backgroundImage: `url(${photoCarreDuRoi1})` }}
          >
            <div className={styles.pageHeroMediaOverlay}>
              <div className={styles.pageHeroMediaTitle}>
                intactile DESIGN, <br />
                création d’interfaces <br />+ subtiles
              </div>
              <LinkWithArrow to="/projets/">
                Découvrez nos réalisations
              </LinkWithArrow>
            </div>
          </div>
          <div className={styles.pageHeroContent}>
            <h1 className={styles.sectionTitle}>Contactez-nous</h1>
            <div className={styles.contactInfo}>
              <address>
                20 rue du Carré du Roi <br />
                34000 Montpellier - France
              </address>
              <div>
                <a className="is-red" href="mailto:contact@intactile.com">
                  contact@intactile.com
                </a>
              </div>
              <div>+33 (0)4 67 52 88 61</div>
            </div>
            <h2 className={styles.sectionTitle}>Suivez-nous</h2>
            <SocialBar ariaPrefix="contact" />
          </div>
        </div>
      </ContentLayout>

      <ContentLayout>
        <div className={styles.formSection}>
          <form
            name="contact"
            mthod="POST"
            data-netlify="true"
            onSubmit={handleSubmit}
          >
            <h2 className={styles.sectionTitle}>Exprimez-vous</h2>
            <p>
              Vous souhaitez prendre rendez-vous, postuler, vous renseigner… cet
              endroit est le vôtre
            </p>
            <div>
              <label htmlFor="email">Email</label>
              <input
                type="text"
                name="email"
                id="email"
                placeholder="L'email sur lequel vous souhaitez être contacté"
                data-error={formStatus === status.INVALID ? "true" : "false"}
              />
            </div>
            <div>
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" />
            </div>
            {formStatus === status.SUCCESS && (
              <div className={styles.formMesssage}>
                Votre message a bien été envoyé
              </div>
            )}
            {formStatus === status.ERROR && (
              <div className={styles.formMesssage} data-error="true">
                Une erreur est survenue lors de l'envoie de votre message,
                veuillez réessayer.
              </div>
            )}
            <div style={{ textAlign: "right" }}>
              <Button type="submit" disabled={formStatus === status.SUBMITTING}>
                Envoyer
              </Button>
            </div>
          </form>
          <div className={styles.formSectionRightContent}>
            <div
              className={styles.pageHeroMedia}
              style={{ backgroundImage: `url(${photoCarreDuRoi2})` }}
            >
              <div className={styles.pageHeroMediaOverlay}>
                <div className={styles.pageHeroMediaTitle}>
                  Une agence de <br />
                  design d’interfaces <br />
                  numériques fondée <br />
                  il y a 20 ans
                </div>
                <LinkWithArrow to="/a-propos/">
                  À propos de notre agence
                </LinkWithArrow>
              </div>
            </div>
            <p>
              Créée par{" "}
              <Link
                to="/publications/interview-yves-rinato/"
                className="is-red"
              >
                Yves Rinato
              </Link>{" "}
              et Claire Ollagnon en novembre 2000, la société est basée à
              Montpellier mais l'équipe intervient partout en France.
            </p>
          </div>
        </div>
      </ContentLayout>

      <ContentLayout>
        <h2 className={styles.sectionTitle}>
          Une équipe pluridisciplinaire à votre service
        </h2>

        <div className={styles.directorContainer}>
          <ColumnLayout image={<img src={portraitRinato} alt="" />} fill>
            <h3 className={styles.employeeFullname}>Yves Rinato</h3>
            <h4 className={styles.employeeRole}>Designer associé, directeur</h4>
            <p>
              Diplômé de l’École Supérieure des Arts Décoratifs (ESAD) de
              Strasbourg. Débute son métier de designer numérique en 1995 au
              sein de Thomson Multimédia, en collaboration avec Philippe Starck
              autour du programme CANAL+, DVD. Après un passage en Allemagne au
              sein de la GmbH DALIM, spécialiste des arts graphiques et de
              l’automatisation des flux de données prépress, il fonde en 2000 la
              société Intactile DESIGN, spécialisée en design IHM.
            </p>
          </ColumnLayout>
        </div>

        <div className={styles.employeesGrid}>
          {grid.employees &&
            grid.employees.map(({ fullName, role, thumbImage }, i) => {
              const thumbnailUrl =
                thumbImage &&
                getUrlFor(thumbImage)
                  .width(344)
                  .height(290)
                  .fit("crop")
                  .crop("center")
                  .url();
              return (
                <div className={styles.employee} key={fullName}>
                  <img src={thumbnailUrl} alt="" />
                  <h3 className={styles.employeeFullname}>{fullName}</h3>
                  <h4 className={styles.employeeRole}>{role}</h4>
                </div>
              );
            })}
        </div>
        <div style={{ textAlign: "center", margin: "64px 0" }}>
          <LinkButton to="/services/">Nos prestations</LinkButton>
        </div>
        <h2 className={styles.sectionTitle}>Associations pro</h2>
        <div className={styles.associationsContainer}>
          <img src={logoIndigodoc} alt="Indigodoc" width="131" height="63" />
          <img src={logoEurobiomed} alt="Eurobiomed" width="162" height="52" />
          <img src={logoApci} alt="APCI" width="149" height="87" />
          <img
            src={logoAerospaceValley}
            alt="Aerospace Valley"
            width="165"
            height="106"
          />
          <img src={logoEden} alt="Cluster EDEN" width="140" height="57" />
          <img src={logoGicat} alt="GICAT" width="181" height="23" />
        </div>
      </ContentLayout>

      <div className={styles.map}>
        <a
          href="https://www.google.com/maps/place/Intactile+DESIGN/@43.6132086,3.868672,15z/data=!4m2!3m1!1s0x0:0x1d06b4b2f2f205be?sa=X&ved=2ahUKEwjZpuPZic_yAhVM4eAKHeKxAzsQ_BJ6BAhAEAU"
          target="_blank"
          rel="noreferrer noopener"
          title="Voir intactile DESIGN sur Google map ›"
        >
          <img
            srcSet={`${mapSmallImage} 800w,
                      ${mapMediumImage} 1536w,
                      ${mapBigImage} 2048w`}
            sizes="100vw"
            src={mapSmallImage}
            alt="Voir intactile DESIGN sur Google map"
          />
        </a>
      </div>

      {pageContent._rawContent &&
        pageContent._rawContent.map(
          ({ _key, sectionContent, backgroundColor }) => (
            <SectionLayout backgroundColor={backgroundColor} key={_key}>
              <ContentLayout>
                <PortableText blocks={sectionContent} />
              </ContentLayout>
            </SectionLayout>
          )
        )}
    </Layout>
  );
};

export default withGraphQLErrors()(ContactPage);
