import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./ColumnLayout.module.scss";

const cx = classNames.bind(styles);

const ColumnLayout = ({ children, image, caption, fill, reverse }) => {
  return (
    <div
      className={cx({
        container: true,
        isFilled: fill,
        isReversed: reverse,
      })}
    >
      {caption && (
        <figure className={styles.image}>
          {image}
          <figcaption>{caption}</figcaption>}
        </figure>
      )}
      {!caption && <div className={styles.image}>{image}</div>}
      <div className={styles.body}>{children}</div>
    </div>
  );
};

ColumnLayout.defaultProps = {
  caption: null,
  fill: false,
  reverse: false,
};

ColumnLayout.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.node.isRequired,
  caption: PropTypes.node,
  fill: PropTypes.bool,
  reverse: PropTypes.bool,
};

export default ColumnLayout;
